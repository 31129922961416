$enable-dark-mode: false; // Not sure why this worked before in the original Front project.

////////////////////
/// Front Dashboard
// Includes bootstrap plus local customizations

@import 'bootstrap/scss/_functions';

@import 'front_dashboard/themes/default';
@import '_bootstrap_vars';
@import 'front_dashboard/front-dashboard/variables';

@import 'bootstrap/scss/_variables';
@import 'bootstrap/scss/_maps';
@import '_bootstrap_utilities';
@import 'bootstrap/scss/_utilities';

@import 'front_dashboard/front-dashboard/utilities';

// Bootstrap core
@import 'bootstrap/scss/bootstrap';

$flag-icons-path: 'flag-icons/flags';
@import 'flag-icons/sass/flag-icons';

@import 'front_dashboard/theme';

/// Front Dashboard
////////////////////

$bootstrap-icons-font-dir: 'bootstrap-icons/font/fonts';
@import 'bootstrap-icons/font/bootstrap-icons';

@import 'company_settings_form';

// project specific CSS goes here
$flagsImagePath: 'intl-tel-input/build/img/';
@import 'intl-tel-input/src/css/intlTelInput';
@import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';

@import '../shared/fontawesome';

@import 'accounts';
@import 'dashboard';

// project specific CSS goes here

////////////////////////////////
//Alerts                      //
////////////////////////////////

// bootstrap alert CSS, translated to the django-standard levels of
// debug, info, success, warning, error

.form-check-input {
  background: white;
}

// django-pagination-boostrap
.pagination {
  float: right !important;
}

///////////////
// Variables //
///////////////

////////////
// Alerts //
////////////

// bootstrap alert CSS, translated to the django-standard levels of
// debug, info, success, warning, error

.alert-debug {
  background-color: $white;
  border-color: $mint-green;
  color: $black;
}

.alert-error {
  background-color: $pink;
  border-color: $dark-pink;
  color: $red;
}

.table td.content-fit,
.table th.content-fit {
  white-space: nowrap;
  width: 1%;
}

.tooltip-inner {
  text-align: left;
  background-color: $tooltip-bg;
  box-shadow: 0px 0px 4px $gray-800;
  opacity: 1 !important;
}
.tooltip.bs-tooltip-right .tooltip-arrow::before {
  border-right-color: $tooltip-bg !important;
}
.tooltip.bs-tooltip-left .tooltip-arrow::before {
  border-left-color: $tooltip-bg !important;
}
.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: $tooltip-bg !important;
}
.tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: $tooltip-bg !important;
}
.required-label::after {
  content: ' *';
  color: red;
}
